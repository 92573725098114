import { Container, Divider, Typography } from '@mui/material';
import './Projectspage.css';
import React from 'react';

function Projectspage() {
  return (
    <>
      <div className='projects-header'>
        <Typography variant="h2"
          sx={{ fontWeight: "bold", textAlign: "center", fontSize: { xs: "36px", md: "40px", lg: "45px" }, paddingTop: { xs: "50px", sm: "100px", lg: "115px" } }}>
          Avere Works with Clients to Define and Deliver Results that Matter
        </Typography>
      </div>
      <div className='projects-wrapper'>
        <h4>Key Projects</h4>
        <Container sx={{ padding: {xs: "20px 18px", sm: "20px 50px 30px"}}}>
          <p style={{ paddingBottom: "20px" }}>Avere leaders play key advisory roles in a number of projects with the aim of tackling some of the most significant challenges through public-private collaboration.</p>
          <h3>California Department of Motor Vehicles (CA DMV) - RealID Automated Document Verification/Validation Project:</h3>
          <p>Avere assisted the California DMV’s implementation of the federal RealID by designing, developing, and delivering a solution to increase efficiencies across the board by 75%. The solution Avere delivered allows DMV customers to upload proof of residency and identity documentation remotely, where 
            the documents are authenticated and the data therein prepared for the statutorily required in-person interview. All told, this project supported the DMV’s ability to 
            efficiently serve tens of millions of California drivers while the workflow automations, screening architecture, and other solutions Avere created are proving useful and 
            scalable to multiple other projects.
          </p>
          <Divider className='divider'/>
          <h3>Court Documents Workflow Automation:</h3>
          <p>Working with one of California’s Courts of Appeals, Avere created an RPA-driven document workflow automation that supports the Court by allowing for the 24/7 review and 
            screening of all submitted briefs for compliance with local rules regarding format, page length, and other requirements. This digitization of what was once a time-consuming 
            manual review allows Court staff to focus their limited time and resources on more pressing matters, furthering every citizen’s access to justice.
          </p>
          <Divider className='divider'/>
          <h3>California Department of Motor Vehicles (CA DMV) Enterprise Content Management Project:</h3>
          <p>Avere is using its expertise in system integration (SI) services to design, develop, configure, and implement a new Enterprise Content Management (ECM) solution for the DMV. 
            based on a commercial ECM software platform. This new system replaced legacy systems with a modern ECM solution that consolidates document resources, a managed centralized 
            repository, and integrated search into multiple content repositories.
          </p>
        </Container>
      </div>
    </>
  );
}

export default Projectspage;